import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Gray_birch_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("display");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "240px",
          height: "360px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="display">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("display");
            })}
          </script>
        </div>
      </div>
      <div>
      <div style={{
          marginTop: getHeightPercentage(360),
        }}></div>
        <main className="treesDescription1">
          <article>
            <h1>Gray Birch Tree</h1>
            <p>
              Gray Birch, also known as Betula populifolia, is a deciduous tree
              native to North America. It is a small to medium-sized tree that
              typically grows up to 50 feet in height, with a trunk diameter of
              up to 12 inches. Gray Birch is known for its distinctive white
              bark, which peels away in thin layers to reveal darker layers
              beneath.
            </p>
            <p>
              The leaves of Gray Birch are simple and alternate, measuring
              between 1 and 3 inches in length. They are triangular in shape
              with serrated edges, and have a dark green color on top and a
              lighter green underneath. In the fall, the leaves turn a bright
              yellow before dropping from the tree.
            </p>
            <p>
              Gray Birch is an important species ecologically, as it provides
              habitat and food for a variety of animals. Birds, such as
              chickadees and woodpeckers, feed on the insects that live in the
              bark and branches of the tree. Deer and rabbits browse on the
              leaves and twigs, and beavers use the wood to build dams and
              lodges. Gray Birch also helps to stabilize soil and prevent
              erosion, particularly in areas that have been disturbed by human
              activities.
            </p>
            <p>
              In addition to its ecological importance, Gray Birch has a number
              of uses for humans. The sap of the tree can be tapped in the early
              spring to make birch syrup, a sweetener that is similar to maple
              syrup. The bark of the tree can be peeled off in thin sheets and
              used for a variety of crafts, such as basket weaving and
              paper-making. The wood of the tree can be used as firewood,
              although it is not as desirable as other species, such as oak or
              maple.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Gray_birch_tree;
