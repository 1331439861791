import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AddUrl from "./pages/addUrl";

import African_baobab_tree from "./pages/african_baobab_tree";
import African_blackwood_tree from "./pages/african_blackwood_tree";
import { American_elm_tree } from "./pages/american_elm_tree";
import Arborvitae_tree from "./pages/arborvitae_tree";
import Balsam_fir_tree from "./pages/balsam_fir_tree";
import { Basswood_tree } from "./pages/basswood_tree";
import { Bassword_tree_4 } from "./pages/bassword_tree_4";
import Black_ash_tree from "./pages/black_ash_tree";
import Black_birch_tree from "./pages/black_birch_tree";
import Bois_dentelle_tree from "./pages/bois_dentelle_tree";
import Butternut_tree from "./pages/butternut_tree";
import Cucumber_tree from "./pages/cucumber_tree";
import DeadVlei_tree from "./pages/deadVlei_tree";
import Dragon_blood_tree from "./pages/dragon_blood_tree";
import Eastern_hemlock_tree from "./pages/eastern_hemlock_tree";
import Eucalyptis_tree from "./pages/eucalyptis_tree";
import Gray_birch_tree from "./pages/gray_birch_tree";
import Hawthorn_tree from "./pages/hawthorn_tree";
import { Basswood_tree_3 } from "./pages/krishna_vat_tree";
import Magnolia_tree from "./pages/magnolia_tree";
import Monkey_puzzle_tree from "./pages/monkey_puzzle_tree";
import Naglingam_tree from "./pages/naglingam_tree";
import Pau_brasil_tree from "./pages/pau_brasil_tree";
import Pin_cherry_tree from "./pages/pin_cherry_tree";
import Red_pine_tree from "./pages/red_pine_tree";
import Saint_helena_gumwood_tree from "./pages/saint_helena_gumwood_tree";
import Sassafras_tree from "./pages/sassafras_tree";
import { Serbian_spruce_tree } from "./pages/serbian_spruce_tree";
import Shagbark_hickory_tree from "./pages/shagbark_hickory_tree";
import ImageContainer from "./pages/imageContainer";

const router = createBrowserRouter([
  {
    path: "/arborvitae_tree",
    element: <Arborvitae_tree />,
  },
  {
    path: "/balsam_fir_tree",
    element: <Balsam_fir_tree />,
  },
  {
    path: "/red_pine_tree",
    element: <Red_pine_tree />,
  },
  {
    path: "/pau_brasil_tree",
    element: <Pau_brasil_tree />,
  },
  {
    path: "/deadVlei_tree",
    element: <DeadVlei_tree />,
  },
  {
    path: "/magnolia_tree",
    element: <Magnolia_tree />,
  },
  {
    path: "/pin_cherry_tree",
    element: <Pin_cherry_tree />,
  },
  {
    path: "/hawthorn_tree",
    element: <Hawthorn_tree />,
  },
  {
    path: "/american_elm_tree",
    element: <American_elm_tree />,
  },
  {
    path: "/basswood_tree",
    element: <Basswood_tree />,
  },
  {
    path: "/serbian_spruce_tree",
    element: <Serbian_spruce_tree />,
  },
  {
    path: "/saint_helena_gumwood_tree",
    element: <Saint_helena_gumwood_tree />,
  },
  {
    path: "/monkey_puzzle_tree",
    element: <Monkey_puzzle_tree />,
  },
  {
    path: "/bois_dentelle_tree",
    element: <Bois_dentelle_tree />,
  },
  {
    path: "/dragon_blood_tree",
    element: <Dragon_blood_tree />,
  },
  {
    path: "/african_baobab_tree",
    element: <African_baobab_tree />,
  },
  {
    path: "/eucalyptis_tree",
    element: <Eucalyptis_tree />,
  },
  {
    path: "/butternut_tree",
    element: <Butternut_tree />,
  },
  {
    path: "/african_blackwood_tree",
    element: <African_blackwood_tree />,
  },
  {
    path: "/sassafras_tree",
    element: <Sassafras_tree />,
  },
  {
    path: "/black_birch_tree",
    element: <Black_birch_tree />,
  },
  {
    path: "/Shagbark_hickory_tree",
    element: <Shagbark_hickory_tree />,
  },
  {
    path: "/eastern_hemlock_tree",
    element: <Eastern_hemlock_tree />,
  },
  {
    path: "/gray_birch_tree",
    element: <Gray_birch_tree />,
  },
  {
    path: "/black_ash_tree",
    element: <Black_ash_tree />,
  },
  {
    path: "/cucumber_tree",
    element: <Cucumber_tree />,
  },
  {
    path: "/krishna_vat_tree",
    element: <Basswood_tree_3 />,
  },
  {
    path: "/naglingam_tree",
    element: <Naglingam_tree />,
  },
  {
    path: "/bassword_tree_4",
    element: <Bassword_tree_4 />,
  },
  {
    path: "/add-url",
    element: <AddUrl />,
  },
  {
    path: "tree/:id",
    element: <ImageContainer />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
