import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;
const Saint_helena_gumwood_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("saint_helena_gumwood_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);


  return (
    <>
    <div
        style={{
          width: "1000px",
          height: "1000px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="saint_helena_gumwood_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("saint_helena_gumwood_tree");
            })}
          </script>
        </div>
      </div>
      <div>
      <div style={{
          marginTop: getHeightPercentage(1000),
        }}></div>
        <main className="treesDescription1">
          <article>
            <h1>Saint Helena Gumwood</h1>
            <p>
              Saint Helena gumwood (Commidendrum robustum) is a species of
              flowering plant native to the island of Saint Helena, an isolated
              British territory located in the South Atlantic Ocean. It is a
              small tree or shrub that grows to a height of about 5-10 meters
              and is found in rocky areas or on slopes in the island's humid
              subtropical climate.
            </p>
            <p>
              Saint Helena gumwood is known for its dense, hard wood which is
              dark brown or black in color and has a fine, straight grain. The
              wood is highly prized for its durability and resistance to rot and
              decay, making it an ideal choice for a variety of applications
              including the construction of buildings, boats, and other
              structures. It is also used in the manufacture of furniture,
              decorative items, and other products.
            </p>
            <p>
              In addition to its wood, Saint Helena gumwood is known for its
              gum, which is a resinous substance that is produced by the tree
              when it is injured. The gum is used as a natural adhesive and has
              been used traditionally in the construction of boats and other
              items.
            </p>
            <p>
              The tree is also valued for its ornamental value and is sometimes
              planted in gardens or as a shade tree. It is also used as a
              traditional medicine in some cultures and is believed to have a
              number of medicinal properties.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Saint_helena_gumwood_tree;
