import React from "react";
import Styles from "./snackbar.module.css";
export const Snackbar = (props) => {
  const { isActive, message } = props;
  return (
    <div
      className={
        isActive
          ? [Styles.snackbar, Styles.fadeIn].join(" ")
          : [Styles.snackbar, Styles.fadeOut].join(" ")
      }
    >
      {message}
    </div>
  );
};
