import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Black_birch_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("black_birch_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "336px",
          height: "280px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="black_birch_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("black_birch_tree");
            })}
          </script>
        </div>
      </div>
      <div>
      <div style={{
          marginTop: getHeightPercentage(280),
        }}></div>
        <main className="treesDescription1">
          <article>
            <h1>Black Birch Tree</h1>
            <p>
              Black Birch, also known as Betula lenta, is a deciduous tree
              native to eastern North America. It is a medium-sized tree that
              can grow up to 75 feet tall, with a trunk diameter of up to 3
              feet. Black Birch is known for its distinctive aromatic bark,
              which has a wintergreen scent and is often used for flavoring in
              various products.
            </p>
            <p>
              The leaves of Black Birch are simple and alternate, measuring
              between 2 and 4 inches in length. They are dark green on top and
              lighter green underneath, with serrated edges. In the fall, the
              leaves turn a vibrant yellow before dropping from the tree.
            </p>
            <p>
              Black Birch produces catkins in the spring, which are long,
              cylindrical clusters of small flowers that hang from the branches.
              The flowers are wind-pollinated and give rise to small, winged
              seeds that are dispersed by the wind.
            </p>
            <p>
              Black Birch is an important tree for wildlife, providing food and
              habitat for a variety of animals. The bark, twigs, and leaves are
              eaten by deer, moose, and other browsing animals, while the seeds
              are eaten by birds and small mammals. The tree also provides
              nesting sites and cover for birds and small mammals.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Black_birch_tree;
