import { useEffect, useState } from "react";

export const provider = ["publift", "GAM"];

export const American_elm_tree = () => {
  const [ad, setAd] = useState(false);
  // const [index, setIndex] = useState(0);

  useEffect(() => {
    // const fetchedIndex = +localStorage?.american_elm_tree_index ?? 0;
    // setIndex(fetchedIndex);

    // if (fetchedIndex >= American_elm_trees_links.length - 1)
    //   localStorage.american_elm_tree_index = 0;
    // else localStorage.american_elm_tree_index = +fetchedIndex + 1;

    // logData(provider[fetchedIndex]);

    setAd(true);
  }, []);

  return (
    <div>
      {ad && (
        <iframe
          scrolling="no"
          style={{
            width: "100vw",
            height: "100vh",
            border: "none",
          }}
          src={American_elm_trees_links[0]}
        />
      )}
    </div>
  );
};

export const American_elm_trees_links = [
  "https://www.jointero.org/american_elm_tree",
  "https://tree.jointero.org/eucalyptis_tree?height=1",
  // "https://tree.jointero.org/magnolia_tree"
];

function generateDeviceId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// export async function logData(provider) {
//   try {
//     let data = {
//       api_key: "d407f17446d4c96cf592af53f2813965",
//       events: [
//         {
//           event_type: "Newtab_page_ad",
//           device_id: generateDeviceId(16),
//           time: Math.floor(Date.now() / 1000),
//           event_properties: { provider },
//         },
//       ],
//     };
//     fetch("https://api.amplitude.com/2/httpapi", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });
//   } catch (error) {
//     console.log(error);
//   }
// }
