import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const African_baobab_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("african_baobab_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <div>
      <div
        style={{
          width: "800px",
          height: "600px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="african_baobab_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("african_baobab_tree");
            })}
          </script>
        </div>
      </div>
      <div
        style={{
          marginTop: getHeightPercentage(600),
        }}
      ></div>
      <main className="treesDescription1">
        <article>
          <h1>African baobab Tree</h1>
          <p>
            The African baobab tree is a species of tree that is native to the
            continent of Africa. It is also known as the "tree of life" due to
            its ability to survive in harsh, dry conditions and its importance
            to local communities.
          </p>
          <p>
            The African baobab tree can grow up to 30 meters (98 feet) tall and
            have a trunk diameter of up to 11 meters (36 feet). It is
            characterized by its thick, gnarled trunk and branches that resemble
            roots. The tree has a long lifespan, with some individuals living
            for over 1,000 years.
          </p>
          <p>
            The African baobab tree is found in savannah and woodland areas
            across Africa, and it is a vital source of food, shelter, and
            medicine for many local communities. The tree's leaves, bark, and
            fruit are used in traditional medicine to treat a variety of
            ailments, and its fruit is a rich source of vitamins and minerals.
            The tree's bark is also used to make rope and cloth, and the tree's
            hollow trunks are often used as storage containers or even as
            homes.In addition to its practical uses, the African baobab tree is
            also a cultural symbol in many parts of Africa. It is revered as a
            sacred tree in some communities, and it is often featured in
            folklore and mythology.
          </p>
          <p>
            Despite its importance, the African baobab tree is facing a number
            of threats. Climate change, habitat loss, and over-exploitation are
            all contributing to a decline in the number of baobab trees.
            Conservation efforts are underway to protect and preserve these
            important trees, including the creation of baobab conservation areas
            and the development of sustainable harvesting practices. allowed
            them to reach large sizes in a short period of time.
          </p>
        </article>
      </main>
    </div>
  );
};

export default African_baobab_tree;
