import React, { useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";
import { Snackbar } from "../Component/Snackbar";
import { useSnackbar } from "../hook/useSnackbar";

const ADMIN_PASSWORD = "4dbef1fb-7d45-4d0e-b386-325f3cfc7a3f";
const BASE_URL = "https://app.jointero.org";

export default function AddUrl() {
  const [isLogin, setIsLogin] = useState(false);
  const { isActive, message, openSnackBar } = useSnackbar();
  const showSnackbarHandler = (text) => {
    openSnackBar(text);
  };
  const [password, setPassword] = useLocalStorage("admin-secret", "");
  const [replacementURL, setReplacementURL] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) setIsLogin(true);
    else showSnackbarHandler("Invalid Secret");
  };

  useEffect(() => {
    if (password === ADMIN_PASSWORD) setIsLogin(true);
  }, []);

  const onURLSubmit = async (e) => {
    e.preventDefault();
    if (!replacementURL) return showSnackbarHandler("Enter URL");

    try {
      const reqBody = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ url: replacementURL }),
      };

      const response = await fetch(
        `${BASE_URL}/api/utility/replacementListRewrite`,
        reqBody
      );

      const result = await response.json();

      if (result.success) {
        setReplacementURL("");
        showSnackbarHandler("URL Submitted");
        return;
      }

      throw new Error("");
    } catch (error) {
      console.log(error);
      showSnackbarHandler("Something went wrong");
    }
  };

  const handleLogout = () => {
    setPassword("");
    setIsLogin(false);
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "grid",
        placeItems: "center",
      }}
    >
      {isLogin ? (
        <>
          <button
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            title="logout"
            onClick={handleLogout}
          >
            <Logout />
          </button>
          <h1 style={{ position: "absolute", top: "40%" }}>
            Paste Replacement URL
          </h1>
          <form
            key="loggedIn"
            onSubmit={onURLSubmit}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <label className="custom-field one">
              <input
                type="text"
                placeholder=" "
                required
                value={replacementURL}
                onChange={({ target: { value } }) => setReplacementURL(value)}
              />
              <span className="placeholder">Enter URL</span>
            </label>
            <button className="btn" type="submit">
              Send
            </button>
          </form>
        </>
      ) : (
        <>
          <h1 style={{ position: "absolute", top: "40%" }}>
            Login to add URLs
          </h1>
          <form
            key="loggedOut"
            onSubmit={onSubmit}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <label className="custom-field one">
              <input
                type="password"
                placeholder=" "
                required
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
              />
              <span className="placeholder">Enter Secret</span>
            </label>

            <button className="btn" type="submit">
              Submit
            </button>
          </form>
        </>
      )}
      <Snackbar isActive={isActive} message={message} />
    </div>
  );
}

function Logout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      className="icon"
      viewBox="0 0 1024 1024"
    >
      <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 01-112.7 75.9A352.8 352.8 0 01512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 01-112.7-75.9 353.28 353.28 0 01-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z"></path>
    </svg>
  );
}
