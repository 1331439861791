import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getHeightMargin } from "../functions";

let timer1;
let countForPopup = 0;

const DeadVlei_tree = () => {
  const [browser, setBrowser] = useState(false);
  useEffect(() => {
    setBrowser(true);
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5693329537819873"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      {browser && (
        <div>
          <div className="treeImage">
            <ins
              className="adsbygoogle"
              style={{
                display: "inline-block",
                width: "300px",
                height: "250px",
              }}
              data-ad-client="ca-pub-5693329537819873"
              data-ad-slot="6882257480"
            ></ins>
          </div>
          <main
            className="treesDescription1"
            style={{
              marginTop: getHeightMargin(250),
            }}
          >
            <article>
              <h1>DeadVlei Tree</h1>
              <p>
                Deadvlei is a clay pan located within the Namib-Naukluft Park in
                Namibia, Africa. It is known for its unique landscape, which
                features ancient, petrified camelthorn trees that stand in stark
                contrast to the white clay pan and orange dunes that surround
                them.
              </p>
              <p>
                The name "Deadvlei" means "dead marsh" in Afrikaans, and the
                area gets its unique appearance from the fact that it was once a
                marsh that was fed by a river that has since dried up. The trees
                that stand in Deadvlei today died around 900 years ago, when the
                climate changed and the river that once watered the area stopped
                flowing.Despite the fact that the trees in Deadvlei are ancient,
                they are well-preserved due to the dry climate and lack of
                decay-causing organisms in the area. The trees stand tall and
                ghostly, with their gnarled branches reaching towards the sky.
              </p>
              <p>
                Deadvlei is a popular tourist destination, and it is often
                visited as part of a trip to the nearby Sossusvlei dunes. The
                area is accessible by car and can be reached via a dirt road
                from the town of Sesriem.
              </p>
              <p>
                In addition to its unique landscape and ancient trees, Deadvlei
                is also home to a variety of wildlife, including oryx,
                springbok, and ostriches. The area is known for its stunning
                sunrises and sunsets, and it is a popular spot for
                photography.Despite its popularity, Deadvlei is still a remote
                and isolated place, and visitors are reminded to respect the
                environment and follow leave no trace principles to help
                preserve the area for future generations.
              </p>
            </article>
          </main>
        </div>
      )}
    </>
  );
};

export default DeadVlei_tree;
