import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Eucalyptis_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("eucalyptis_tree");
    });

    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      {/* <Helmet>
      </Helmet> */}
      <div
        id="eucalyptis_tree"
        style={{ minWidth: "728px", minHeight: "90px" }}
      >
        <script>
          {window.googletag.cmd.push(function () {
            window.googletag.display("eucalyptis_tree");
          })}
        </script>
      </div>
      <div>
        <div className="treeImage">
          {/* <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "728px", height: "90px" }}
            data-ad-client="ca-pub-5693329537819873"
            data-ad-slot="5358776495"
          ></ins> */}
        </div>
        <main
          className="treesDescription1"
          style={{
            position:'absolute',
            top: "90px",
          }}
        >
          <article>
            <h1>Eucalyptus tree</h1>
            <p>
              Eucalyptus is a genus of trees. Eucalypt is also the common name
              for seven similar plants from the tribe Eucalypteae, all from
              Australasia. They are Eucalyptus, Corymbia, Angophora,
              Stockwellia, Allosyncarpia, Eucalyptopsis and Arillastrum.There
              are over 700 species of eucalypts, and almost all of them are in
              Australia. Eucalypts can be found in almost every part of the
              Australia, and they are adapted to many different habitats. Their
              distribution has an implication: they must have evolved after
              Australia lost contact with the rest of Gondwana. Apart from the
              island of New Guinea, which is historically connected to
              Australia, and the Philipines, and a couple of small islands, the
              eucalypts are not naturally found in any other countries.
            </p>
            <p>
              Today, specimens of the Australian Mountain Ash are among the
              tallest trees in the world. They grow at up to 92 metres in
              height,[3] and are the tallest of all flowering plants: taller
              trees such as the coast redwood are all conifers. There is
              evidence that at the time of European settlement of Australia some
              Mountain Ash were indeed the tallest plants in the world.
            </p>
            <p>
              Fruit that has not been dried, roasted, or fermented is harmful to
              humans and has a severe purgative effect. The wood is used to make
              yokes, oars, and makoros in Botswana. A growing number of skincare
              products now contain extracts from Kigelia africana's fruit,
              flower, and bark because of the plant's potent anti-inflammatory
              and antioxidant properties. The fruit's tough outer shell (skin)
              can be hollowed out, cleaned, and transformed into variously
              sized, functional containers. Because of its unique fruit and
              gorgeous blossoms, the tree is frequently grown as an ornamental
              tree in tropical climates. Planting locations should be carefully
              chosen as the fruit might fall and seriously hurt people or damage
              parked cars.
            </p>
            <p>
              The dried fruits are used to prepare an alcoholic beverage
              (muratina in Kikuyu, Aembu, and Kamba), which is an essential part
              of traditional festivities in central Kenya, notably among the
              Kikuyu, Embu, and Akamba groups that live around Mount Kenya.
              After being collected, the fruit is cut in half along its axis and
              then dried in the sun. In order to activate and inoculate the
              fresh ones with yeast, the dried fruits are then placed into a
              fermentation container with older, already-used muratina (prural).
              The cultures get stronger as they are utilized, producing more
              carbon dioxide and alcohol from honey and sugarcane juice. The
              alcoholic beverage is typically saved for major events like
              marriages, dowry rituals, and funerals.
            </p>
            <p>
              The long, flexible stems from the flowers, which subsequently turn
              into fruit, dangle from the branches (2–6m long). The flowers grow
              in panicles and are bell-shaped, 10 cm wide, orange to maroon or
              purple green, and considerably darker and more waxy than those of
              the African tulip tree. Individual flowers are horizontally
              aligned rather than hanging down. The fruit is a woody berry that
              can be up to 20 cm long and up to 18 cm in diameter. It hangs down
              on long, rope-like peduncles and typically weighs between 5 and 10
              kg, but can reach 12 kg on rare occasions. The fruit pulp is dense
              and packed with seeds.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
// <div style={{width:"100vw",fontSize:"30px",fontWeight:900,height:"100vh",backgroundImage: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);" }}>320x50</div>

export default Eucalyptis_tree;
