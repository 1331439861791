import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

let timer1;
let countForPopup = 0;

const Magnolia_tree = () => {
  useEffect(() => {
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5693329537819873"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      <div>
        <div className="treeImage">
          <ins
            class="adsbygoogle"
            style={{ display: "inline-block", width: "728px", height: "90px" }}
            data-ad-client="ca-pub-5693329537819873"
            data-ad-slot="7358661360"
          ></ins>
        </div>
        <main
          className="treesDescription1"
          style={{
            marginTop: "10px",
          }}
        >
          <article>
            <h1>Magnolia tree</h1>
            <p>
              Magnolia is a genus of flowering trees that is native to the
              Americas, Asia, and the Caribbean. There are over 200 species of
              magnolia, which vary in size, shape, and color. Magnolia trees are
              known for their large, showy flowers, which can be white, pink,
              purple, or yellow in color.
            </p>
            <p>
              Magnolia trees are prized for their ornamental value and are often
              planted in gardens and parks. Many species of magnolia are
              slow-growing and can reach heights of up to 50 feet (15 meters)
              with a spread of 25 feet (7.6 meters). They have a distinctive,
              pyramid-shaped canopy and large, glossy leaves. The tree's flowers
              are often fragrant and are produced in the spring before the
              leaves emerge.
            </p>
            <p>
              Magnolia trees are popular as ornamental plants due to their
              beauty and the fact that they are relatively easy to care for.
              They prefer well-drained, acidic soil and need plenty of sunlight,
              but they are tolerant of a wide range of conditions. In addition
              to their ornamental value, magnolia trees are also used in
              traditional medicine in some parts of the world. The tree's bark
              and flowers are used to treat a variety of ailments, including
              fever, inflammation, and respiratory problems.
            </p>
            <p>
              Despite their popularity and cultural significance, some species
              of magnolia are at risk of extinction due to habitat loss and
              over-exploitation. Conservation efforts are underway to protect
              and preserve these important trees, including the creation of
              protected areas and the development of sustainable harvesting
              practices.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Magnolia_tree;
