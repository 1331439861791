import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;
const Dragon_blood_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("dragon_blood_tree");
    });

    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <div>
       <div
        style={{
          width: "270px",
          height: "150px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="dragon_blood_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("dragon_blood_tree");
            })}
          </script>
        </div>
      </div>
      <div style={{
          marginTop: getHeightPercentage(150),
        }}></div>
      <main className="treesDescription1">
        <article>
          <h1>Dragon Blood Tree</h1>
          <p>
            The Dragon Blood Tree (Dracaena cinnabari) is a species of tree that
            is native to the Socotra archipelago, a group of four islands
            located in the Indian Ocean off the coast of Yemen. It is a member
            of the Asparagaceae family and is closely related to plants such as
            agave, yucca, and aloe.
          </p>
          <p>
            The Dragon Blood Tree is an iconic and distinctive species, known
            for its unusual appearance and the bright red resin that it
            produces. The tree grows to a height of up to 15 meters (50 feet)
            and has a crown of long, slender, spiky leaves. Its trunk is often
            contorted and twisted, giving it a gnarled and ancient appearance.
          </p>
          <p>
            The tree gets its name from the red resin, called "dragon's blood,"
            that it produces. This resin is produced by the tree as a defense
            mechanism to protect itself from damage and disease. When the tree
            is injured or cut, it exudes a thick, red resin that hardens to form
            a protective layer over the wound. The resin has been used for
            centuries for a variety of medicinal, cosmetic, and ritual purposes.
          </p>
          <p>
            The Dragon Blood Tree is found only on the island of Socotra, where
            it grows in the arid and rocky landscape. It is adapted to survive
            in the harsh desert conditions, with a deep root system that helps
            it to tap into underground water sources. The tree is also able to
            store water in its trunk, which helps it to survive prolonged
            periods of drought.Despite its tough exterior, the Dragon Blood Tree
            is under threat from a variety of factors. The tree is listed as
            "vulnerable" on the International Union for Conservation of Nature
            (IUCN) Red List, and its population is declining due to habitat
            destruction, overgrazing by goats, and the collection of the red
            resin. In addition, the tree is threatened by climate change, as
            rising temperatures and changing rainfall patterns may make it more
            difficult for the tree to survive.Conservation efforts are underway
            to protect the Dragon Blood Tree and its habitat. The tree has been
            designated as a protected species on Socotra, and efforts are being
            made to promote sustainable harvesting of the red resin. The tree is
            also being propagated in botanical gardens and arboreta around the
            world, in order to ensure its survival for future generations.
          </p>
        </article>
      </main>
    </div>
  );
};

export default Dragon_blood_tree;
