import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Arborvitae_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("arborvitae_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "300px",
          height: "250px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="arborvitae_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("arborvitae_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: "10px",
          }}
        ></div>
        <main className="treesDescription1">
          <article>
            <h1>Arborvitae Tree</h1>
            <p>
              A coniferous tree or shrub in the Cupressaceae family with the
              name of Thuja (cypress family). The genus has five species, with
              two indigenous to North America and three to eastern Asia. The
              genus is sister to Thujopsis and monophyletic. Common names for
              its members include cedars, thujas, and arborvitaes (from the
              Latin for "tree of life").
            </p>
            <p>
              Thuja are evergreen trees that can reach heights of 3 to 61
              meters. Their reddish-brown bark has a stringy quality. The shoots
              are flat, with only one plane of side shoots. With the exception
              of tiny seedlings in their first year, which have needle-like
              leaves, the leaves are scale-like and 1 to 10 mm long. Along the
              branches, the scale leaves are arranged in four rows, alternately
              in decussate pairs. The male cones are tiny, barely noticeable,
              and found at the ends of the twigs. The female cones begin
              similarly inconspicuous and mature when they are 6 to 8 months
              old, when they are about 1 to 2 cm long and have 6 to 12
              overlapping, thin, leathery scales, each of which contains one to
              two small seeds and has a pair of narrow lateral wings.
            </p>
            <p>
              The five species of Thuja range in size from tiny to giant
              evergreen trees with branchlets that are flattened. The leaves are
              organized in four ranks of oppositely clustered, flattened
              fan-shaped groups with resin glands. On larger branchlets, mature
              leaves vary from younger leaves in that they have acute, erect,
              free apices. The flattened lateral branchlets have scale-like,
              tightly packed leaves, and the lateral pairs are keeled. The
              lateral leaves are shorter than the facial leaves, with the
              exception of T. plicata. The single flowers are terminally
              generated. Pollen cones having 2–6 pairs of sporophylls that
              contain 2-4 pollen grains. The first year is when the ellipsoid, 9
              to 14 mm long seed cones mature and open.
            </p>
            <p>
              Formerly classified as Thuja orientalis, a different species that
              is only distantly related to it is now known as Platycladus
              orientalis. The closest relatives of Thuja are Tetraclinis
              articulata and Thujopsis dolabrata, which differ from one another
              by having quadrangular (rather than flattened) foliage and cones
              with four thick, woody scales.
            </p>
            <p>
              Similar to many other conifer species, the genus Thuja can be
              found in its ancestral forms in northern European Cretaceous
              rocks. Over time, it is discovered to have migrated from northern
              to more southern regions until the Pliocene, when it vanished from
              the continent. In the Dakota Miocene deposits, thuja is also
              known.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
export default Arborvitae_tree;
