import { useEffect, useState } from "react";
import { logData, provider } from "./american_elm_tree";

export const Basswood_tree = () => {
  const [ad, setAd] = useState(false);
  // const [index, setIndex] = useState(0);

  useEffect(() => {
    // const changeCountInLocal = localStorage.changeCountInLocal
    //   ? +localStorage.changeCountInLocal
    //   : 0;

    // if (changeCountInLocal) {
    //   const fetchedIndex = +localStorage?.basswood_tree_index ?? 0;
    //   setIndex(fetchedIndex);
    //   if (fetchedIndex >= Basswood_trees_links.length - 1)
    //   localStorage.basswood_tree_index = 0;
    //   else localStorage.basswood_tree_index = +fetchedIndex + 1;

    //   localStorage.changeCountInLocal = 0;
    //   // logData(provider[fetchedIndex]);
    // } else localStorage.changeCountInLocal = changeCountInLocal + 1;

    setAd(true);
  }, []);

  return (
    <div>
      {ad && (
        <iframe
          scrolling="no"
          style={{
            width: "100vw",
            height: "100vh",
            border: "none",
          }}
          src={Basswood_trees_links[0]}
        />
      )}
    </div>
  );
};

export const Basswood_trees_links = [
  "https://www.jointero.org/basswood_tree",
  // "https://tree.jointero.org/deadVlei_tree",
  "https://tree.jointero.org/butternut_tree?height=1",
];
