import React, { useEffect } from "react";
let timer1;
let countForPopup = 0;
const Naglingam_tree = () => {
  useEffect(() => {
    // window.adsbygoogle = window.adsbygoogle || [];
    // window.adsbygoogle?.push({});
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      <div>
        <div className="treeImage">
          {/* <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "728px", height: "90px" }}
            data-ad-client="ca-pub-5693329537819873"
            data-ad-slot="7358661360"
          ></ins> */}
        </div>
        <main className="treesDescription1">
          <article>
            <h1>Nagalingam tree</h1>
            <p>
              Nagalingam tree, also known as Nagalinga Pushpa or Shiva Linga
              Tree, is a rare and sacred tree found in the Western Ghats region
              of India. The tree is known for its unique flowers that resemble
              the lingam, the symbol of Lord Shiva. It is considered a
              manifestation of Lord Shiva and is revered by Hindus as a holy
              tree. The tree is found in the dense forests of the Western Ghats,
              and its flowers bloom only once a year for a few hours.
            </p>
            <p>
              The Nagalingam tree is a small tree that grows up to 10 meters in
              height. The bark of the tree is grayish-brown and has many small
              cracks. The leaves are simple and are arranged alternately on the
              branches. The flowers of the tree are small, bell-shaped, and
              yellowish-green in color. They grow in clusters, and each flower
              has a protruding central stalk that resembles the lingam.
            </p>
            <p>
              The Nagalingam tree has been used in Ayurvedic medicine for
              centuries due to its medicinal properties. The bark and leaves of
              the tree are used to treat various ailments such as fever,
              diarrhea, dysentery, and respiratory infections. The tree is also
              believed to have spiritual and religious significance, and its
              flowers are used in various Hindu rituals and ceremonies.
            </p>
            <p>
              The Nagalingam tree is a rare and endangered species, and its
              habitat is under threat due to deforestation and habitat
              destruction. The tree is protected by law, and its cultivation and
              felling are strictly prohibited. Conservation efforts are underway
              to protect the Nagalingam tree and its habitat, and awareness
              campaigns are being conducted to educate people about the
              importance of preserving this sacred tree.
            </p>
            <p>
              The Nagalingam tree is also a symbol of the rich biodiversity of
              the Western Ghats region. The region is known for its unique and
              diverse flora and fauna and is considered one of the 18
              biodiversity hotspots in the world. The Western Ghats are home to
              many endemic species of plants and animals, including the
              Nagalingam tree.
            </p>
            <p>
              In conclusion, the Nagalingam tree is a rare and sacred tree that
              holds immense spiritual, religious, and medicinal significance. It
              is a symbol of the rich biodiversity of the Western Ghats and is
              an important part of the cultural heritage of India. It is
              essential to protect this endangered species and its habitat to
              preserve the ecological balance and ensure the survival of this
              sacred tree for future generations.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Naglingam_tree;
