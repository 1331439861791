import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";
let timer1;
let countForPopup = 0;
const Red_pine_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("red_pine_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "468px",
          height: "60px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="red_pine_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("red_pine_tree");
            })}
          </script>
        </div>
      </div>
      <div>
      <div style={{
          marginTop: getHeightPercentage(60),
        }}></div>
        <main className="treesDescription1">
          <article>
            <h1>red pine tree</h1>
            <p>
              A medium-sized natural evergreen, the red pine can be found from
              eastern Canada south through Minnesota and east to New York. It
              frequently grows in sandy soils and is typically found on hills,
              slopes, ridges, and plains. It is occasionally cultivated in
              Nebraska. A variety of birds, mammals, and other creatures use the
              tree's seeds as food and its physical structure as cover, as is
              the case with most pines.
            </p>
            <p>
              Red pine thrives on sandy loams with full sun, average moisture
              levels, and good drainage. It prefers climates with cool summers
              and chilly winters and has very little tolerance for shade. Wide
              range of soils are tolerated, however some urban pollution is seen
              to be intolerable. Although the tree is uncommon in Nebraska, it
              should be taken into account for landscaping plantings,
              windbreaks, and screens.
            </p>
            <p>
              A coniferous evergreen tree with tall, straight growth is the red
              pine. It typically grows between 20 and 35 meters tall, with a
              trunk diameter of 1 meter, occasionally growing as high as 43.77
              meters. Conical at first, the crown ages into a narrow, rounded
              dome. The tree gets its name from the distinguishing feature of
              its bark, which is thick and gray-brown at the base and thin,
              flaky, and bright orange-red in the upper crown. The bark's cracks
              may have a hint of crimson color. Due to the self-pruning nature
              of the plant, elder trees may have very extensive lengths of
              branchless trunk beneath the canopy and rarely have dead branches
              on them.
            </p>
            <p>
              The leaves are brittle, needle-like, dark yellow-green, arranged
              in fascicles of two, and 12 to 18 centimeters long. Although
              described as a red pine characteristic in some sources, other pine
              species also have leaves that break neatly when bent. The cones
              are nearly stalkless, symmetrical oval shapes that measure 4-6 cm
              long by 2.5 cm wide when immature and purple before turning
              nut-blue and opening to 4-5 cm wide.
            </p>
            <p>
              Red pine is unique for having a relatively consistent form and
              little genetic diversity throughout its distribution, which may
              indicate that it has recently survived a near-extinction event.
              Red pine populations from Newfoundland are genetically distinct
              from most mainland populations, according to a genetic analysis of
              nuclear microsatellite polymorphisms among populations scattered
              throughout its natural range. This finding is consistent with the
              fact that this highly self-pollinating species dispersed from
              various glacial refugia. It occurs throughout the Appalachian
              Mountains of Virginia and West Virginia, as well as a few isolated
              pockets in extreme northern New Jersey and northern Illinois. It
              is found from Newfoundland west to Manitoba and south to
              Pennsylvania. It inhabits a range of environments.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
// <div style={{width:"100vw",fontSize:"30px",fontWeight:900,height:"100vh",backgroundImage: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);" }}>300x100</div>

export default Red_pine_tree;
