import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Eastern_hemlock_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("eastern_hemlock_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "256px",
          height: "60px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="eastern_hemlock_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("eastern_hemlock_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: getHeightPercentage(60),
          }}
        ></div>
        <main className="treesDescription1">
          <article>
            <h1>Eastern Hemlock Tree</h1>
            <p>
              Eastern Hemlock, also known as Canadian Hemlock or Tsuga
              canadensis, is a coniferous tree that is native to the eastern
              regions of North America. It is a long-lived and slow-growing
              species that can reach up to 70 feet in height, with a trunk
              diameter of up to 5 feet. Eastern Hemlock is a key component of
              the eastern North American forest ecosystem, providing a range of
              ecological services.
            </p>
            <p>
              Eastern Hemlock is an evergreen tree that has short needles that
              are less than an inch in length. The needles are dark green on top
              and have two white lines on the underside. The cones of Eastern
              Hemlock are small, less than an inch in length, and can be found
              on the upper branches of the tree. The bark of Eastern Hemlock is
              thick and dark brown in color, with deep furrows and ridges.
            </p>
            <p>
              Eastern Hemlock is an important tree for wildlife, providing
              habitat and food for a variety of animals. Its dense canopy
              provides cover for birds and small mammals, while the needles are
              eaten by deer and other herbivores. The seeds and cones are also a
              food source for birds and small mammals, including squirrels and
              chipmunks.
            </p>
            <p>
              Eastern Hemlock also plays a key role in forest ecology. Its
              needles and twigs decompose slowly, creating a thick layer of
              organic matter on the forest floor. This layer helps to retain
              moisture and nutrients, and provides habitat for a range of
              microorganisms that play a critical role in the forest ecosystem.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Eastern_hemlock_tree;
