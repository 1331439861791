import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Balsam_fir_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("balsam_fir_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "160px",
          height: "612px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="balsam_fir_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("balsam_fir_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: getHeightPercentage(612),
          }}
        ></div>
        <main className="treesDescription1">
          <article>
            <h1>balsam fir tree</h1>
            <p>
              A small to medium-sized evergreen tree, the balsam fir can grow to
              a height of 27 meters on rare occasions. Dark-green, thick leaves
              make up the short conic crown. On young trees, the bark is smooth,
              grey, and covered in blisters of resin that, when ruptured, tend
              to spray. On older trees, the bark becomes fissured, rough, or
              scaly. The 15 to 30 mm long, flat, needle-like leaves have two
              white stomatal bands below and a slightly notched tip. They are
              dark green above and frequently have a small patch of stomata near
              the tip. On the stalk, they are grouped spirally, but the leaf
              bases are twisted, giving the impression that there are two
              roughly horizontal rows of leaves on either side of the shoot.
            </p>
            <p>
              Native Americans have employed balsam fir for therapeutic and
              medical purposes for thousands of years. Many animals and people
              consume the needles right off the tree. Tea contains a higher
              dosage of active ingredient. Vitamin C, which has been
              investigated for its impact on bacterial and viral illnesses, is
              present in balsam fir.
            </p>
            <p>
              The eastern spruce budworm, a serious pest in the eastern United
              States and Canada, prefers the balsam fir as its primary host
              plant. Balsam fir can suffer severe defoliation during cyclical
              population breakouts, which might greatly slow radial growth. The
              tree can die as a result. Over 75% of balsam fir trees in certain
              areas were eradicated by an outbreak in Quebec in 1957.
            </p>
            <p>
              Generally speaking, the male reproductive organs emerge and mature
              more quickly than the female reproductive organs. Microsporangia
              found in the male organs divide to create sporogenous tissue,
              which is made up of cells that become archesporial cells. These
              become spherical and filled with starch granules to become
              microspores, or pollen-mother cells. Four haploid microspores are
              created when the microspores go through meiosis in the spring;
              these microspores eventually develop into pollen grains. The
              pollen is discharged when the microsporangia of the adult male
              strobilus become visible.
            </p>
            <p>
              Megasporangiate females are larger than males. It has bracts and
              megasporophylls, which are arranged spirally and each contain two
              ovules. These eventually form a nucellus, which is where a mother
              cell is created. As the megagametophyte's first cell, meiosis
              takes place, resulting in the production of a megaspore. The
              megaspore's nucleus thickens during cell division, and when cells
              differentiate, prothallial tissue containing an ovum is produced.
              The endosperm is then created by the remaining undifferentiated
              cells.
            </p>
            <p>
              Some pollen grains that are released by the male structure land on
              the female strobilus and travel to the ovule. The pollen tube
              starts to produce at this time, and when the sperm and egg finally
              come into contact, fertilization takes place.
            </p>
            <p>
              In the northeastern United States, both of the species' variants
              are quite popular as Christmas trees. Balsam firs harvested for
              Christmas are produced on huge plantations rather than being
              collected from the forest. One of Quebec and New England's top
              exports is balsam fir. The most fragrant of all Christmas tree
              varieties, it is prized for its deep green needles, natural
              conical shape, and needle retention after cutting.
            </p>
            <p>
              Family farms passed down from generation to generation make up
              many of these plantations. Shearing, growing, and other
              cultivation methods were secretly transmitted from grandparents to
              grandchildren.
            </p>
            <p>
              Between 1964 and 2019, the US Capitol Christmas Tree was made from
              balsam fir six times.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Balsam_fir_tree;
