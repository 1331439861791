import { useEffect, useState } from "react";

export const  Serbian_spruce_tree = () => {
    const [ad, setAd] = useState(false);
    useEffect(() => {
      setAd(true);
    }, []);
    return (
      <div>
        {ad && (
          <iframe
          scrolling="no"
            style={{
              width: "100vw",
              height: "100vh",
              border:"none"
            }}
            src="https://tree.jointero.org/krishna_vat_tree"
          />
        )}
      </div>
    );
  };