import React, { useEffect } from "react";
let timer1;
let countForPopup = 0;
export const Basswood_tree_3 = () => {
  useEffect(() => {
    // window.adsbygoogle = window.adsbygoogle || [];
    // window.adsbygoogle?.push({});
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      <div>
        <div className="treeImage">
          {/* <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "300px", height: "250px" }}
            data-ad-client="ca-pub-5693329537819873"
            data-ad-slot="4255368768"
          ></ins> */}
        </div>
        <main className="treesDescription1">
          <article>
            <h1>Krishna Vat tree</h1>
            <p>
              Krishna Vat tree is an ancient tree located in the courtyard of
              the Krishna Vat temple in Mathura, Uttar Pradesh, India. It is
              believed to be over 5,000 years old and is considered a sacred
              tree by Hindus. According to legend, Lord Krishna used to play
              around this tree during his childhood days.
            </p>
            <p>
              The tree is said to have miraculous properties and is believed to
              fulfill the wishes of devotees who tie threads around its trunk.
              The tree is also known as the Kalpavriksha tree, which means a
              wish-fulfilling tree. It is believed that the tree can provide
              relief from diseases and can bring good luck to those who offer
              their prayers to it.
            </p>
            <p>
              The Krishna Vat tree has a unique appearance, with its branches
              drooping down towards the ground and its roots visible above the
              ground. The tree is also surrounded by a small fence to protect it
              from the crowds of devotees who visit the temple. The tree is a
              symbol of the deep spiritual connection that the devotees have
              with Lord Krishna and is considered a manifestation of his divine
              presence.
            </p>
            <p>
              The Krishna Vat tree is a source of inspiration for artists,
              poets, and writers who have praised its beauty and significance in
              their works. It is a popular subject in Indian art and literature,
              and many songs and poems have been composed in its honor. The tree
              is also a symbol of the rich cultural heritage of India, which has
              a long and diverse history of spirituality and religious
              traditions.
            </p>
            <p>
              The Krishna Vat tree has also witnessed many historical events,
              including the attacks on the temple by foreign invaders. Despite
              its age and vulnerability, the tree has survived through the ages,
              becoming a testament to the enduring power of faith and devotion.
              Today, the tree and the Krishna Vat temple continue to attract
              thousands of visitors every year, who come to seek blessings and
              offer their prayers to Lord Krishna.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
