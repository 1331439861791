import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

let timer1;
let countForPopup = 0;

export const Bassword_tree_4 = () => {
  useEffect(() => {
    var MMT = window.$MMT || {};
    MMT.cmd = MMT.cmd || [];
    MMT.cmd.push(function () {
      MMT.display.slots.push(["d673dd3f-7e74-435f-9fc4-0ff67e32458c"]);
    });
    // window.googletag.cmd.push(function () {
    //   window.googletag.display("div-gpt-ad-1677079603506-0");
    // });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <script
          async={true}
          type="text/javascript"
          src="//monu.delivery/site/a/e/ddf0a9-f0da-4ab7-bfca-ebf0aeadc440.js"
          data-cfasync="false"
        ></script>
      </Helmet>
      {/* <div
        style={{
          width: "728px",
          height: "90px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div
          id="div-gpt-ad-1677079603506-0"
          style={{ minWidth: "728px", minHeight: "90px" }}
        >
          <script>
            {window.googletag.cmd.push(function () {
              window.googletag.display("div-gpt-ad-1677079603506-0");
            })}
          </script>
        </div>
      </div> */}
      <div id="mmt-d673dd3f-7e74-435f-9fc4-0ff67e32458c"></div>
      <div>
        <div className="treeImage">
          <div data-fuse="22851576046"></div>
        </div>
        <main className="treesDescription">
          <article>
            <h1>American Elm Tree</h1>
            <p>
              Elms are prized for their elegant, regal form, branches that
              spread out like fountains, and green leaves that change to gold in
              the fall. In the growing towns of the 19th century, the American
              elm was the most widely planted tree, and by the 20th century,
              many streets were lined with solely elms, providing summer shade
              from a cathedral-like canopy of their branches. Sadly, for a long
              time, it was impossible to promote the American elm (Ulmus
              americana) due to its susceptibility to the deadly Dutch elm
              disease infection. Through their grafted root systems or with the
              aid of a beetle, Dutch elm disease was able to decimate elm after
              elm when it invaded the US in the 1950s.
            </p>
            <p>
              Ulmus americana, sometimes referred to as the American elm, the
              white elm, or the water elm, is a species of elm that is
              indigenous to eastern North America. It may be found growing wild
              from Nova Scotia in the west to Alberta and Montana, and in the
              south to Florida and central Texas. A rather resilient tree, the
              American elm can endure winter temperatures as low as 42 °C. In
              locations where Dutch elm disease is not present, trees can
              survive for several hundred years. The Sauble Elm, a prime
              specimen of the species, reached a height of 43 meters and a
              diameter of 196 centimeters when it was cut down in 1968 in
              Ontario, Canada. A tree-ring count revealed that the Sauble Elm
              had first sprung in 1701, and it was down in 1968.
            </p>
            <p>
              U. americana has been known for more than 80 years to be a
              tetraploid, or having twice the typical number of chromosomes,
              making it unusual within the genus. But according to a 2011
              research by the USDA's Agricultural Research Service, roughly 20%
              of wild American elms are diploid and may potentially belong to a
              different species. Additionally, a number of triploid trees that
              are exclusively found in culture, including "Jefferson," have a
              high level of resistance to DED, the disease that decimated
              American elms in the 20th century. Due to their noticeably smaller
              cells than tetraploid parent trees, diploid parent trees may also
              be more disease-resistant, according to this.
            </p>
            <p>
              Due to its tolerance for urban environments, quick growth, and
              attractive form, American elm was a frequent street and park tree
              in the 19th and early 20th centuries. However, this resulted in
              the species being extremely overplanted, especially to create
              living arches over roadways, which ultimately led to the
              development of an unhealthy elm monoculture that lacked disease
              and insect resistance. Elms do not often grow in pure stands, and
              the trees utilized for landscaping were produced from only a few
              varieties, which resulted in very little genetic variation. Before
              DED, these trees were widely used due to their quick growth and
              long lifespan, which allowed them to reach large sizes in a short
              period of time.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
