import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Bois_dentelle_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("bois_dentelle_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <div>
      <div
        style={{
          width: "560px",
          height: "315px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="bois_dentelle_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("bois_dentelle_tree");
            })}
          </script>
        </div>
      </div>
      <div
        style={{
          marginTop: getHeightPercentage(315),
        }}
      ></div>
      <main className="treesDescription1">
        <article>
          <h1>Bois dentelle</h1>
          <p>
            Bois dentelle, also known as the "lace wood tree," is a species of
            tree that is native to the island of Madagascar. It is known for its
            delicate, lace-like bark, which gives the tree its name.
          </p>
          <p>
            Bois dentelle is a small to medium-sized tree, reaching heights of
            up to 15 meters (50 feet). It has a slender, upright trunk and a
            dense, spreading canopy. The tree's bark is thin and smooth, with a
            distinctive lace-like pattern that is created by small fissures and
            cracks. The tree's leaves are dark green, shiny, and lance-shaped,
            and its flowers are small and white. Bois dentelle is found in dry,
            deciduous forests and woodlands in Madagascar. It is a popular
            ornamental tree due to its unique appearance and is often used in
            landscaping and garden design.
          </p>
          <p>
            In addition to its use as an ornamental tree, bois dentelle is also
            used in traditional medicine in Madagascar. The tree's bark is used
            to treat a variety of ailments, including fever, coughs, and wounds.
            The tree's leaves are also used to make a tea that is believed to
            have digestive and diuretic properties.
          </p>
          <p>
            Despite its popularity and cultural importance, bois dentelle is
            facing a number of threats. Habitat loss, deforestation, and
            over-exploitation are all contributing to a decline in the number of
            bois dentelle trees. Conservation efforts are underway to protect
            and preserve these unique trees, including the creation of protected
            areas and the development of sustainable harvesting practices.
          </p>
        </article>
      </main>
    </div>
  );
};

export default Bois_dentelle_tree;
