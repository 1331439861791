import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Hawthorn_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("hawthorn_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "970px",
          height: "250px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="hawthorn_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("hawthorn_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <main
          className="treesDescription1"
          style={{
            marginTop: getHeightPercentage(250),
          }}
        >
          <article>
            <h1>hawthorn tree</h1>
            <p>
              Depending on the taxonomic interpretation, the genus may have one
              or several species. Many of the 1000 or more species recognized by
              certain botanists in the past are apomictic microspecies. Around
              200 species are thought to be an acceptable number. The genus most
              likely originally arose in the Eocene, and its ancestral regions
              were likely Eastern North America and Europe, which at the time
              were still tightly connected by the North Atlantic Land Bridge.
              The earliest known leaves of the genus are from North America's
              Eocene period, whereas those from Europe's Oligocene period are
              the earliest known leaves. The genus is broken down into parts and
              then into series. There is still no section assigned to the Series
              Montaninsulae.
            </p>
            <p>
              Many different bird and mammal species can find food and refuge
              among hawthorn trees, and many nectar-feeding insects depend on
              the blossoms for reproduction. Many Lepidoptera species, like the
              little eggar moth E. lanestris, use hawthorns as feeding plants
              for its larvae. In the winter, haws are crucial for animals,
              especially for thrushes and waxwings, which consume them and
              disseminate the seeds in their droppings. Although it is a
              prevalent misconception that hawthorns can be multiplied by
              cutting, rootless stem fragments make this difficult. Suckers or
              small plants from the wild are frequently transplanted. It takes
              one or two years for seeds to germinate and need to be stratified.
            </p>
            <p>
              An extract of hawthorn used as an adjuvant in the treatment of
              chronic heart failure has been shown to provide "a considerable
              effect in symptom control and physiologic outcomes," according to
              a 2008 Cochrane Collaboration meta-analysis of earlier research.
              Hawthorn preparations "have tremendous potential as an effective
              therapy in the treatment of cardiovascular disease," according to
              a 2010 assessment. The review stated that although "many possible
              theoretical interactions between Crataegus and traditional drugs
              have been proposed, none have [yet] been verified" and recommended
              further research into the optimal amounts. Hawthorn contains
              tannins, flavonoids, oligomeric proanthocyanidins, and phenolic
              acids among other phytochemicals.
            </p>
            <p>
              Various hawthorn species have been employed in traditional
              medicine. The ingredients in the goods are frequently extracted
              from Crataegus monogyna, Crataegus laevigata, or allied Crataegus
              species, "together known as hawthorn," without necessarily
              differentiating between these species. Traditional Chinese
              medicine makes use of the dried fruits of Crataegus pinnatifida,
              also known as "shan zh" in Chinese, primarily as a digestive aid.
              Crataegus cuneata, also known as sanzashi in Japanese, is a
              closely related species that serves a similar purpose. Herbal
              medicine uses Crataegus laevigata and other species because it is
              thought to improve cardiovascular health. The Kutenai people of
              northwest North America used the red and black hawthorn fruits for
              traditional medicine and food, respectively.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
// <div style={{width:"100vw",fontSize:"30px",fontWeight:900,height:"100vh",backgroundImage: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);" }}>468x60</div>

export default Hawthorn_tree;

// export async function getServerSideProps(context) {
//   // Perform your redirect logic here
//   return {
//     redirect: {
//       destination: "https://www.dgtl.ro/clients/jointero/970x250.html",
//       permanent: false,
//     },
//   };
// }
