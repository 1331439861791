import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";
let timer1;
let countForPopup = 0;

const Butternut_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("butternut_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      {/* <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5693329537819873"
        crossOrigin="anonymous"
      ></script> */}
      <div id="butternut_tree" style={{ width: "300px", height: "250px" }}>
        <script>
          {window.googletag.cmd.push(function () {
            window.googletag.display("butternut_tree");
          })}
        </script>
      </div>
      <div>
        <div className="treeImage">
          {/* <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "300px", height: "250px" }}
            data-ad-client="ca-pub-5693329537819873"
            data-ad-slot="6882257480"
          ></ins> */}
        </div>
        <main
          className="treesDescription1"
          style={{
            marginTop: getHeightPercentage(250),
          }}
        >
          <article>
            <h1>butternut tree</h1>
            <p>
              Juglans cinerea is a species of walnut that is indigenous to the
              eastern United States and southeast Canada. It is sometimes
              referred to as butternut or white walnut[3]. The distribution area
              stretches from southern Quebec east to New Brunswick, south to
              northern Alabama, and southwest to northern Arkansas. It also
              stretches west to Minnesota. Most of the Southern United States
              lack it. As an off-site species, the species is also common at
              intermediate elevations (approximately 610 meters above sea level)
              in the Columbia River basin in the Pacific Northwest. As recently
              as January 26, 2015, trees in the Imnaha River watershed were
              found to have 2.1 m class range diameter at breast height. Unlike
              black walnut, butternut prefers a cooler environment, and it does
              not grow in the Deep South.
            </p>
            <p>
              Like other members of the Juglandaceae family, butternut begins to
              leaf out in the spring when the duration of the day reaches 14
              hours. This process is dependent on photoperiod rather than air
              temperature. In the most northern and southern reaches of its
              range, this can vary by up to a month. Fall leaf fall begins at a
              very early time, when there are only 11 hours of daylight. It is a
              monoecious species. Female (pistillate) flowers are short terminal
              spikes on stems from the current year, and male (staminate)
              flowers are unnoticeable, yellow-green slender catkins that emerge
              from auxiliary buds. The stigma of every female bloom is pale
              pink. On any given tree, flowers of both sexes don't typically
              mature at the same time. The fruit, a nut with a lemon-like form,
              is produced in clusters of two to six.
            </p>
            <p>
              Butternut thrives best on well-drained soils and along stream
              banks. On dry, dense, or barren soils, it is infrequently seen.
              But in dry, rocky soils, especially those of limestone origin, it
              thrives more than black walnut. The stony soils of New England,
              where black walnut is mainly absent, are part of the butternut's
              range.
            </p>
            <p>
              Butternut is most usually found on slopes, in the talus of rock
              ledges, on benches and terraces along streams, in coves, and in
              other well-drained areas. It can be found in the Virginia
              Mountains up to an elevation of 1,500 meters, which is much higher
              than black walnut. The fauna consumes the nuts.
            </p>
            <p>
              In the mixed mesophytic forest, butternut can be found with
              numerous other tree species in several hardwood kinds. In the four
              northern and central forest cover types of sugar maple-basswood,
              yellow poplar-white oak-northern red oak, beech-sugar maple, and
              river birch-sycamore, it is an allied species. Basswood, black
              cherry, beech, black walnut, elm, hemlock, hickory, oak, red
              maple, sugar maple, yellow poplar, white ash, and yellow birch are
              some of the trees that are frequently seen together. It frequently
              coexists with sweet birch in the northeastern portion of its
              habitat, while it occasionally coexists with white pine in the
              northern portion. Butternut trees are sporadic in forest stands,
              yet they can be common in certain localities.
            </p>
            <p>
              Despite being categorized as "Special Concern" in Kentucky,
              "Exploitably Vulnerable" in New York State, and "Threatened" in
              Tennessee, the species is not federally recognized as threatened
              in the US. In 2005, the butternut was added to Canada's list of
              endangered species by the Committee on the Status of Endangered
              Wildlife in Canada.
            </p>
            <p>
              In an effort to preserve the species and develop resistance to the
              butternut canker disease, the USDA Forest Service planted about 60
              grafted butternut trees in a seed orchard in Huntingburg, Indiana,
              in 2012. The project involves personnel from the Hoosier National
              Forest, the Hardwood Tree Improvement and Regeneration Center at
              Purdue University, the Northern Research Station, the Eastern
              Region National Forest Genetics Program, and the Forest Service.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
// <div style={{width:"100vw",fontSize:"30px",fontWeight:900,height:"100vh",backgroundImage: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);" }}>320x100</div>

export default Butternut_tree;
