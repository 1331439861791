import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Image120X600 from "../asset/120×600.png";
import Image160X600 from "../asset/160×600.png";
import Image200X200 from "../asset/200×200.png";
import Image250X250 from "../asset/250×250.png";
import Image300X250 from "../asset/300×250.png";
import Image300X600 from "../asset/300×600.png";
import Image336X275 from "../asset/336×275.png";
import Image728X90 from "../asset/728x90.png";
import Image970X90 from "../asset/970×90.png";
import { useEffect } from "react";

export default function ImageContainer() {
  const { id } = useParams();
  const [src, setSrc] = useState("");

  useEffect(() => {
    console.log(id);
    switch (id.trim()) {
      case "120x600":
        setSrc(Image120X600);
        break;
      case "160x600":
        setSrc(Image160X600);
        break;
      case "200x200":
        setSrc(Image200X200);
        break;
      case "250x250":
        setSrc(Image250X250);
        break;
      case "300x250":
        setSrc(Image300X250);
        break;
      case "300x600":
        setSrc(Image300X600);
        break;
      case "336x275":
        setSrc(Image336X275);
        break;
      case "728x90":
        setSrc(Image728X90);
        break;
      case "970x90":
        setSrc(Image970X90);
        break;

      default:
        setSrc("");
        break;
    }
  }, [id]);

  return <div>{src && <img src={src} alt="image-render" />}</div>;
}
