import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";
let timer1;
let countForPopup = 0;
const Monkey_puzzle_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("monkey_puzzle_tree");
    })
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      <div style={{ width: "378px", height: "574px",margin: 0, padding: 0, overflow: "hidden" }}>
        <div id="monkey_puzzle_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("monkey_puzzle_tree");
            })}
          </script>
        </div>
      </div>
      <div>
      <div style={{
          marginTop: getHeightPercentage(378),
        }}></div>
        <main className="treesDescription1">
          <article>
            <h1>monkey puzzle</h1>
            <p>
              monkey puzzle tree, (Araucaria araucana), also called Chile pine,
              an evergreen ornamental and timber conifer of the family
              Araucariaceae, native to the Andes Mountains of South America.
              Although the tree was declared a natural monument in Chile in 1976
              to afford it protection from logging, the species is listed as an
              endangered species by the IUCN Red List of Threatened Species
              because of illegal felling activity and habitat fragmentation in
              its native habitat.
            </p>
            <p>
              The monkey puzzle tree may grow to a height of 45–50 metres
              (150–164 feet) with a diameter of 2.5 metres (8 feet) and may live
              for more than 700 years. Its spiral arrangement of rigid
              needle-pointed leaves along stiff branches inspired its common
              name, evoked by a comment about the challenge this prickly network
              might pose to climbing monkeys. The plants are usually dioecious,
              meaning male and female cones are most commonly borne on separate
              individuals. The female cones are somewhat spherical in shape and
              can produce about 200 edible seeds.
            </p>
            <p>
              Monkey puzzle tree is known for its hard, dense wood which is used
              in a variety of applications including the construction of
              buildings, furniture, and other products. The tree is also valued
              for its ornamental value and is often used as an ornamental tree
              in gardens and parks. It is a popular choice for landscaping due
              to its distinctive appearance and ability to thrive in a variety
              of climates.
            </p>
            <p>
              In addition to its use as an ornamental tree, monkey puzzle tree
              has a number of other uses. The tree's seeds are edible and are
              sometimes used as a food source. The tree is also used in
              traditional medicine in some cultures and is believed to have a
              number of medicinal properties.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Monkey_puzzle_tree;
