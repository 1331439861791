import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";
let timer1;
let countForPopup = 0;

const African_blackwood_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("african_blackwood_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      {/* <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5693329537819873"
        crossOrigin="anonymous"
      ></script> */}
      <div
        id="african_blackwood_tree"
        style={{ width: "300px", height: "250px" }}
      >
        <script>
          {window.googletag.cmd.push(function () {
            window.googletag.display("african_blackwood_tree");
          })}
        </script>
      </div>
      <div>
        <div className="treeImage">
          {/* <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "300px", height: "250px" }}
            data-ad-client="ca-pub-5693329537819873"
            data-ad-slot="6882257480"
          ></ins> */}
        </div>
        <main
          className="treesDescription1"
          style={{
            marginTop: getHeightPercentage(250),
          }}
        >
          <article>
            <h1>African Blackwood</h1>
            <p>
              African blackwood (Dalbergia melanoxylon) is a species of
              flowering plant native to Central and East Africa. It is a
              hardwood tree that is highly prized for its dense, fine-grained
              wood which is dark brown or black in color. The wood is often used
              in the manufacture of high-quality furniture, musical instruments,
              decorative items, and other products.
            </p>
            <p>
              One of the key characteristics of African blackwood is its
              durability and resistance to rot and decay. It is also resistant
              to termites and other insects, making it an ideal choice for
              outdoor use. The wood is also known for its excellent stability
              and ability to hold fine details, which makes it well-suited for
              carving and turning.
            </p>
            <p>
              African blackwood is harvested from forests in countries such as
              Tanzania, Mozambique, and Madagascar. It is often harvested from
              trees that are at least 50 years old, and the wood is typically
              dried for several months before being used. Due to the high demand
              for African blackwood and the limited supply of trees, it can be
              quite expensive.
            </p>
            <p>
              In addition to its use in woodworking and other industries,
              African blackwood has a number of other uses. It is sometimes used
              in traditional medicine and as a source of fuel. The tree is also
              prized for its ornamental value and is sometimes planted as a
              shade tree or in gardens.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default African_blackwood_tree;
