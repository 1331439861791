import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Shagbark_hickory_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("shagbark_hickory_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "1200px",
          height: "280px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="shagbark_hickory_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("shagbark_hickory_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: getHeightPercentage(280),
          }}
        ></div>
        <main className="treesDescription1">
          <article>
            <h1>Shagbark Hickory Tree</h1>
            <p>
              Shagbark Hickory, also known as Carya ovata, is a large deciduous
              tree that is native to eastern North America. It is a
              slow-growing, long-lived tree that can reach up to 100 feet in
              height, with a trunk diameter of up to 3 feet. Shagbark Hickory is
              named for its distinctive bark, which peels away from the trunk in
              long, shaggy strips.
            </p>
            <p>
              The leaves of Shagbark Hickory are large, typically measuring
              between 8 and 14 inches in length, and are composed of 5 to 9
              leaflets. The leaflets are dark green on top and paler underneath,
              and have serrated edges. In the fall, the leaves turn a vibrant
              yellow before dropping from the tree.
            </p>
            <p>
              The fruit of Shagbark Hickory is a nut enclosed in a thick, hard
              shell. The nut is edible and has a sweet, nutty flavor, although
              it is difficult to crack open. The nuts were an important food
              source for Native American tribes and early settlers, who used
              them in a variety of dishes, including soups, stews, and pies.
            </p>
            <p>
              Shagbark Hickory is an important tree for wildlife, providing
              habitat and food for a variety of animals. The nuts are eaten by
              squirrels, chipmunks, and other rodents, while the leaves and bark
              are eaten by deer. The tree also provides cover and nesting sites
              for birds and small mammals.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Shagbark_hickory_tree;
