import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Pin_cherry_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("pin_cherry_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      <div
        style={{
          width: "300px",
          height: "600px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="pin_cherry_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("pin_cherry_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: getHeightPercentage(600),
          }}
        ></div>
        <main className="treesDescription1">
          <article>
            <h1>pin cherry tree</h1>
            <p>
              The North American cherry species Prunus pensylvanica, sometimes
              referred to as bird cherry, fire cherry, pin cherry, and red
              cherry, belongs to the genus Prunus. From Newfoundland and
              southern Labrador to British Columbia and the southern Northwest
              Territories, the species is common throughout a large portion of
              Canada. Additionally, it is widespread in the Great Lakes region
              and New England. As far south as northern Georgia and eastern
              Tennessee in the Appalachian Mountains, it is also present there.
              The pin cherry also grows sporadically in the Rocky Mountains, as
              well as in the Black Hills of South Dakota and as far south as
              Colorado.
            </p>
            <p>
              The pin cherry can sprout from seeds and grow new leaves. Insects
              pollinate the bisexual flowers of this plant. Birds, tiny mammals,
              and gravity all spread seeds. Pin cherry seeds can survive for
              several years in the soil as part of their reproductive strategy.
              Long-term accumulation of seeds can result in soil seed banks that
              are still functional after 50 to 100 years. Sprouting is a kind of
              asexual reproduction, and pin cherry bushes frequently grow in
              thickets as a result. The pin cherry has a relatively brief
              lifespan of only 20 to 40 years after a quick development. Its
              roots tend to develop laterally and have a shallow root system. It
              is a crucial source of food for numerous animals. In the Great
              Lakes states and the boreal forest region, winter moose browse it.
            </p>
            <p>
              Individual pin cherry thickets are frequently destroyed if exposed
              to fire, despite the fact that they have been reported to sprout
              after being removed. Nevertheless, they have evolved as a species
              by creating their seed banks, which are fed by the nutrients in
              the resulting ash residue and shielded from the most intense heat
              by their soil cover. After a fire or other disturbance, seeds that
              may have lain dormant for years will quickly sprout thanks to the
              new environmental conditions. These traits, especially in the
              northern hardwood forest, allow groupings of Pin cherry thickets
              to dominate many burned-over regions due to the seedlings' quick
              early growth. Several Lepidoptera species feed on pin cherries.
            </p>
            <p>
              Though interest in producing pin cherry fruit commercially has
              recently emerged, pin cherries currently have little economic
              significance. The fruit can be used to make jams, jellies, and
              preserves and is edible. Pin cherry wood has minimal commercial
              value because it is light, fairly soft, porous, and weak. It is
              typically not exploited for lumber production and is regarded as a
              noncommercial species. However, it is common across a variety of
              places and produces a significant amount of biomass in a short
              period of time. According to descriptions, the species can be
              harvested for fiber and fuel with short rotations and vigorous
              management.
            </p>
            <p>
              Prunus pensylvanica typically has a straight stem, a narrow,
              round-topped crown, and grows as a shrub or small tree. It can
              reach heights of 5–15 meters (16–49 feet) and a diameter of 10–51
              centimeters (4–20 inches). The largest trees, which can reach 30 m
              (98 ft) in height, can be found on the western slopes of the Great
              Smoky Mountains in the southern Appalachians. It has narrow leaves
              that are 4-11 cm long and 1-4.5 cm broad. Flowers are found in
              clusters of five to seven, with each flower measuring one
              centimeter across. Each drupe, or fruit, has a single, 4-6
              mm-diameter seed enclosed in a hard "stone," and they range in
              size from 4 to 8 millimeters.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};
// <div style={{width:"100vw",fontSize:"30px",fontWeight:900,height:"100vh",backgroundImage: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);" }}>160x600</div>

export default Pin_cherry_tree;

// export async function getServerSideProps(context) {
//   // Perform your redirect logic here
//   return {
//     redirect: {
//       destination: "https://www.dgtl.ro/clients/jointero/300x600.html",
//       permanent: false,
//     },
//   };
// }
