import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";

let timer1;
let countForPopup = 0;

const Sassafras_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("sassafras_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);


  return (
    <>
    <div
        style={{
          width: "180px",
          height: "150px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="sassafras_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("sassafras_tree");
            })}
          </script>
        </div>
      </div>
      <div>
      <div style={{
          marginTop: getHeightPercentage(150),
        }}></div>
        <main className="treesDescription1">
          <article>
            <h1>Sassafras Tree</h1>
            <p>
            Sassafras is a deciduous tree native to eastern North America, ranging from southern Maine to Florida and west to Texas. It is known for its unique and fragrant aroma, as well as its use in traditional medicine and as a flavoring in foods and beverages.
            </p>
            <p>
            The sassafras tree can grow up to 60 feet tall, with a slender trunk and branches that form a broad, irregular crown. The leaves are distinctive, with three different shapes appearing on the same tree: a mitten-shaped leaf, a three-lobed leaf, and an oval-shaped leaf. The bark of the tree is reddish-brown and deeply furrowed, and the wood is lightweight and brittle.
            </p>
            <p>
            Sassafras has a long history of use in traditional medicine. Native American tribes used it to treat a variety of ailments, including wounds, fevers, and diarrhea. European settlers learned of its medicinal properties and incorporated it into their own medical practices. Sassafras was used as a tonic, diuretic, and blood purifier, and was even believed to have mystical and spiritual properties.
            </p>
            <p>
            In the early 20th century, it was discovered that safrole, a chemical compound found in sassafras oil, was a potential carcinogen. As a result, the use of sassafras oil in food and medicine was banned by the FDA in 1960. While the ban has been lifted in some cases, the use of sassafras oil is still heavily regulated.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Sassafras_tree;
