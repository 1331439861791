import React, { useEffect } from "react";
import { getHeightPercentage } from "../functions";
let timer1;
let countForPopup = 0;
const Pau_brasil_tree = () => {
  useEffect(() => {
    window.googletag.cmd.push(function () {
      window.googletag.display("pau_brasil_tree");
    });
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
  }, []);
  return (
    <>
      <div
        style={{
          width: "728px",
          height: "90px",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div id="pau_brasil_tree">
          <script type="text/javascript">
            {window.googletag.cmd.push(function () {
              window.googletag.display("pau_brasil_tree");
            })}
          </script>
        </div>
      </div>
      <div>
        <div className="treeImage"></div>
        <main
          className="treesDescription1"
          style={{
            marginTop: getHeightPercentage(90),
          }}
        >
          <article>
            <h1>Pau brasil Tree</h1>
            <p>
              Pau brasil, also known as the Brazilwood tree, is a species of
              tree that is native to the Atlantic Forest of Brazil. It is known
              for its hard, red wood, which was once used to make a variety of
              products, including musical instruments, furniture, and dyes.
            </p>
            <p>
              Pau brasil is a large tree, reaching heights of up to 60 feet (18
              meters) and a trunk diameter of up to 3 feet (1 meter). It has a
              straight, cylindrical trunk and a dense, rounded canopy. The
              tree's bark is smooth and reddish-brown in color, and its leaves
              are dark green and shiny. The tree's flowers are small and yellow,
              and its fruit is a small, round capsule that contains seeds.
            </p>
            <p>
              Pau brasil is found in the Atlantic Forest of Brazil, a biodiverse
              region that is home to a wide variety of plant and animal species.
              The tree is an important source of hardwood for local communities
              and is used to make a variety of products, including furniture,
              musical instruments, and dyes. The tree's bark is also used in
              traditional medicine to treat a variety of ailments, including
              fevers and infections.
            </p>
            <p>
              Despite its importance, pau brasil is facing a number of threats.
              Habitat loss, deforestation, and over-exploitation are all
              contributing to a decline in the number of pau brasil trees.
              Conservation efforts are underway to protect and preserve these
              important trees, including the creation of protected areas and the
              development of sustainable harvesting practices.
            </p>
          </article>
        </main>
      </div>
    </>
  );
};

export default Pau_brasil_tree;
